import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Module, UserContext } from '../../../../../../Context/UserContext/UserContext';
import { useComplianceSession } from '../../../../../../Context/ComplianceSessionContext';
import { ModuleContext } from '../../../../../../Context/ModuleContext/ModuleContext';
import { useIntl } from 'react-intl';

interface ILawData {
  id: number;
  name: string;
}

export const useComplianceState = () => {
  const history = useHistory();
  const { createComplianceSession } = useComplianceSession();
  const { complianceUserFor,hasAccessToModule } = useContext(UserContext);
  const { setHeader } = useContext(ModuleContext);
  const { formatMessage } = useIntl();
  const [lawsSelectedForCompliance, setLawsSelectedForCompliance] = useState<ILawData[]>([]);
  const [enabled, setEnabled] = useState<boolean>(false);
  const [plan,setPlan]=useState<any>({})
  const [planEdit,setPLanEdit]=useState(false)
  const hasCompliance = (predicate: (law: ILawData) => boolean) => lawsSelectedForCompliance.some(predicate);

  const toggleCompliance = (row: ILawData) => {
    if (hasCompliance((x) => x.id === row.id)) {
      setLawsSelectedForCompliance(lawsSelectedForCompliance.filter((x) => x.id !== row.id));
    } else {
      setLawsSelectedForCompliance([...lawsSelectedForCompliance, row]);
    }
  };

  const startComplianceSession = () => {
    if (lawsSelectedForCompliance.length > 0) {
      // const planID:any=plan.id
      const session = createComplianceSession(lawsSelectedForCompliance,plan);
      setEnabled(false);
      setLawsSelectedForCompliance([]);
      if (session?.todo?.length) {
      if(planEdit){
        history.push(`${session.todo[0].complianceUrl}/edit`);
      }else{
        history.push(session.todo[0].complianceUrl);
      }
    }
    }
  };
  useEffect(() => {
    var menu = []
    if (hasAccessToModule(Module.Administration)||hasAccessToModule(Module.Organization)) {
      menu = [
        {
          title: formatMessage({ id: 'law-portal.legal.compliance.sub-menu.planning.lek' }),
          to: `/law-portal/legal-compliance-control/planning`,
        },
        {
          title: formatMessage({ id: 'law-portal.legal.compliance.sub-menu.carry.lek' }),
          to: `/law-portal/legal-compliance-control/carry`,
        },
        {
          title: formatMessage({ id: 'law-portal.legal.compliance.sub-menu.reports.lek' }),
          to: `/law-portal/legal-compliance-control/reports`,
        },
      ]
    }else{
      menu=[
        {
          title: formatMessage({ id: 'law-portal.legal.compliance.sub-menu.carry.lek' }),
          to: `/law-portal/legal-compliance-control/carry`,
        },
        {
          title: formatMessage({ id: 'law-portal.legal.compliance.sub-menu.reports.lek' }),
          to: `/law-portal/legal-compliance-control/reports`,
        },
      ]
    }
    setHeader(formatMessage({ id: 'law-portal.legal.compliance.header', defaultMessage: 'Legal compliance control' }), menu);
  }, [setHeader, formatMessage]);
  return {
    enableCompliance: complianceUserFor.laws.length > 0 ? () => setEnabled(true) : undefined,
    disableCompliance: () => setEnabled(false),
    complianceEnabled: enabled,
    toggleCompliance,
    startComplianceSession,
    hasCompliance,
    isComplianceUserFor: (lawId: number) => complianceUserFor.laws.some((x) => x.id === lawId),
    isComplianceUserForreq:(reqId: number) => complianceUserFor.requirements.some((x) => x.id === reqId),
    setLawsSelectedForCompliance:(laws:any)=>setLawsSelectedForCompliance(laws),
    setPlan:(plan:any)=>setPlan(plan),
    plan,
    setPLanEdit
  };
};
