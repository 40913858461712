import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { Container } from '../../../../Components';
import { ModuleContext } from '../../../../Context/ModuleContext/ModuleContext';
import { CarryLK } from './CarryLK';
import { PlanningLK } from './PlanningLK';
import { ReportsLK } from './ReportsLK';
import { CreatePlannig } from './PlanningLK/CreatePlanning';
import { CreateLawComplianceReport } from './CarryLK/Compliance/Create';
import { useAptorApi } from '../../../../Api';
// import { Grid } from '@material-ui/core';
// import { LawInternalComments } from './CarryLK/List/InternalComments';
import { ConfirmationSessionProvider } from '../../../../Context/ComplianceSessionContext';
import { Module, UserContext } from "../../../../Context/UserContext/UserContext";

export interface ILawInfo {
  name: string;
  number: string;
  category: string;
}
const ComplianceView = () => {
  const match = useRouteMatch<{ id: string }>();
  const id = parseInt(match.params.id);
  const { api, abortController } = useAptorApi();
  const [info, setInfo] = useState<ILawInfo>();
  useEffect(() => {
    const checkType = window.location.pathname.split("/")
    if (checkType?.length && checkType[5] === "other-requirements") {
      api.getLawPortalRequirementInfo<ILawInfo>(id).then((result) => {
        if (abortController.current.signal.aborted) {
          return;
        }
        setInfo(result);
      });
    } else {
      api.getLawPortalLawInfo<ILawInfo>(id).then((result) => {
        if (abortController.current.signal.aborted) {
          return;
        }
        setInfo(result);
      });
    }
  }, [api, abortController, id]);

  if (info === undefined) {
    return <></>;
  }

  return (
    // <Grid container spacing={2}>
    <CreateLawComplianceReport />

  );
};
export const LegalComplianceControl = () => {
  const { formatMessage } = useIntl();
  const { setHeader } = useContext(ModuleContext);
  const { hasAccessToModule } = useContext(UserContext);
  useEffect(() => {
    var menu = []
    if (hasAccessToModule(Module.Administration)||hasAccessToModule(Module.Organization)) {
      menu = [
        {
          title: formatMessage({ id: 'law-portal.legal.compliance.sub-menu.planning.lek' }),
          to: `/law-portal/legal-compliance-control/planning`,
        },
        {
          title: formatMessage({ id: 'law-portal.legal.compliance.sub-menu.carry.lek' }),
          to: `/law-portal/legal-compliance-control/carry`,
        },
        {
          title: formatMessage({ id: 'law-portal.legal.compliance.sub-menu.reports.lek' }),
          to: `/law-portal/legal-compliance-control/reports`,
        },
      ]
    }else{
      menu=[
        {
          title: formatMessage({ id: 'law-portal.legal.compliance.sub-menu.carry.lek' }),
          to: `/law-portal/legal-compliance-control/carry`,
        },
        {
          title: formatMessage({ id: 'law-portal.legal.compliance.sub-menu.reports.lek' }),
          to: `/law-portal/legal-compliance-control/reports`,
        },
      ]
    }
    setHeader(formatMessage({ id: 'law-portal.legal.compliance.header', defaultMessage: 'Legal compliance control' }), menu);
  }, [setHeader, formatMessage]);
  return (
    <Container fullWidth>
      <ConfirmationSessionProvider>
        <Switch>
          <Route path="/law-portal/legal-compliance-control/carry" component={CarryLK} />
          <Route path="/law-portal/legal-compliance-control/plan/:planid(\d+)/law/:id(\d+)/compliance/report" component={ComplianceView} />
          <Route path="/law-portal/legal-compliance-control/plan/:planid(\d+)/law/:id(\d+)/compliance/report/edit" component={ComplianceView} />
          <Route path="/law-portal/legal-compliance-control/plan/:planid(\d+)/other-requirements/:id(\d+)/compliance/report" component={ComplianceView} />
          <Route path="/law-portal/legal-compliance-control/plan/:planid(\d+)/other-requirements/:id(\d+)/compliance/report/edit" component={ComplianceView} />
          {/* <Route path= "/law-portal/legal-compliance-control/create-compliance" component={CreateLawComplianceReport}/> */}
          <Route exact path="/law-portal/legal-compliance-control/planning" component={PlanningLK} />
          <Route exact path="/law-portal/legal-compliance-control/planning/create-new-planning" component={CreatePlannig} />
          <Route path="/law-portal/legal-compliance-control/reports" exact component={ReportsLK} />
          <Route path="/law-portal/legal-compliance-control">
            {hasAccessToModule(Module.Administration)||hasAccessToModule(Module.Organization) ?
              <Redirect to="/law-portal/legal-compliance-control/planning" />
              :
              <Redirect to="/law-portal/legal-compliance-control/carry" />
            }
          </Route>
        </Switch>
      </ConfirmationSessionProvider>
    </Container>
  );
};
