import React, { useContext, useEffect, useState } from 'react';
import { useFormField } from '../../../../../../Components/Form/FormField';
import { useIntl } from 'react-intl';
import { Tooltip, Typography } from '@material-ui/core';
import { Form, Panel } from '../../../../../../Components';
import {
  // FormFieldValue,
  FormValues
} from '../../../../../../Components/Form/Form.types';
import { AptorApi, useAptorApi } from '../../../../../../Api';
import { useStyles } from './CreatePlanning.styles';
import { entityToSelectOption } from '../../../../../../Utilities';
import {
  AccessLevel,
  LawPortalFeature,
  // PlanFeature,
  UserContext
} from "../../../../../../Context/UserContext/UserContext";
// import { useHistory } from "react-router-dom";
// import moment from "moment";
import { ComplianceTable } from "./ComplianceTable";
import { InfoOutlined } from "@material-ui/icons";
interface IProps {
  setNewCreate: any,
  newCreate: boolean,
  getCompliancePlan: () => void,
  isEdit?: boolean,
  planData?: any,
  // setEditPlanID?: any,
  setDuplicatePlanData?: any,
  isDuplicate?: boolean,
  setEditData?: any,
  handleAllControl:()=>void
}

export const CreatePlannig = ({ setNewCreate, newCreate, getCompliancePlan,handleAllControl, isEdit, planData, setDuplicatePlanData, isDuplicate, setEditData }: IProps) => {
  const { formatMessage } = useIntl();
  const { companyUnits, customerId, hasAccessToFeature } = useContext(UserContext);
  const classes = useStyles();
  // const history = useHistory()
  const { api } = useAptorApi()
  // const [active, setActive] = useState<boolean>(true);
  // const [reminder, setReminder] = useState<boolean>(true);
  const [selectedRows, setSelectedRows] = React.useState<any>([]);
  const [type, setType] = useState<any>("")
  const [companyUnit,setCompanyUnit]=useState(null)
  // const [typeField, setTypeField] = useState<any>([])
  const reminderDateOption = [
    {
      id: 0, name: formatMessage({ id: "law-portal.compliance.create.plan.remainder.week" }, { number: 1 })
    },
    {
      id: 1, name: formatMessage({ id: "law-portal.compliance.create.plan.remainder.weeks" }, { number: 2 })
    },
    {
      id: 2, name: formatMessage({ id: "law-portal.compliance.create.plan.remainder.weeks" }, { number: 3 })
    },
    {
      id: 3, name: formatMessage({ id: "law-portal.compliance.create.plan.remainder.month" }, { number: 1 })
    },
    {
      id: 4, name: formatMessage({ id: "law-portal.compliance.create.plan.remainder.months" }, { number: 2 })
    },
    {
      id: 5, name: formatMessage({ id: "law-portal.compliance.create.plan.remainder.months" }, { number: 3 })
    },
    {
      id: 6, name: formatMessage({ id: "law-portal.compliance.create.plan.remainder.months" }, { number: 6 })
    },
    {
      id: 7, name: formatMessage({ id: "law-portal.compliance.create.plan.remainder.year" }, { number: 1 })
    }
  ]
  const accessToOurLaws = hasAccessToFeature(LawPortalFeature.OurLaws, AccessLevel.Read);
  const accessToOtherRequirements = hasAccessToFeature(LawPortalFeature.OtherRequirements, AccessLevel.Read);
  // let typeField=[
  //   {id:0,name:formatMessage({ id: "law-portal.plan.type.law",defaultMessage:'Legislation'}) },
  //   {id:1,name:formatMessage({ id: "law-portal.plan.type.other-req",defaultMessage:'Other requirements' })}
  // ]
  const typeField: any = []
  if (accessToOurLaws) {
    typeField.push({ id: 0, name: formatMessage({ id: "law-portal.plan.type.law", defaultMessage: 'Legislation' }) })
  }
  if (accessToOtherRequirements) {
    typeField.push({ id: 1, name: formatMessage({ id: "law-portal.plan.type.other-req", defaultMessage: 'Other requirements' }) })
  }

  useEffect(() => {
    if (isEdit || isDuplicate) {
      // setActive(planData.isRecurrent ? false : true)
      // setReminder(planData.activateReminder ? false : true)
      const typeData: any = typeField[planData.type]
      setType(typeData)
      let companyUnitData:any=entityToSelectOption(planData.customerCompanyUnitId)
     setCompanyUnit(companyUnitData)
    } else {
      if (accessToOurLaws && accessToOtherRequirements) {
        setType('')
      } else if (accessToOtherRequirements) {
        const typeData: any = typeField[typeField?.findIndex((val:any)=>val.id===1)]
        setType(typeData)
      } else if (accessToOurLaws) {
        const typeData: any = typeField[typeField?.findIndex((val:any)=>val.id===0)]
        setType(typeData)
      }
    }
  }, [])
  useEffect(() => {
    if (isEdit || isDuplicate) {
      getPlanlawsId()
    }
  }, [])
  const getPlanlawsId = async () => {
    const planLaws = await api.getPlanAllLawID(planData.id)
    setSelectedRows(planLaws.legislationIds)
  }
  const namePlanning = useFormField({
    label: formatMessage({ id: 'law-portal.legal.planned.form.name' }),
    required: true,
    name: 'name',
    type: 'text',
    initialState: isEdit ? { value: planData.name } : undefined
  });
  const chooseCompanyUnit = useFormField({
    type: 'single-select',
    name: 'CustomerCompanyUnitId',
    required: true,
    label: formatMessage({ id: 'law-portal.legal.planned.form.company.unit' }),
    options: companyUnits.map(entityToSelectOption),
    checkedValue(value) {
      setCompanyUnit(value)
    },
    initialState: isEdit || isDuplicate ? entityToSelectOption(planData.customerCompanyUnitId) : undefined
  });

  const startFromDate = useFormField({
    label: formatMessage({ id: 'law-portal.legal.planned.form.start.date' }),
    name: 'StartDate',
    type: 'date',
    required: true,
    initialState: isEdit || isDuplicate ? { value: planData.startDate } : undefined,
  });
  const endFromDate = useFormField({
    label: formatMessage({ id: 'law-portal.legal.planned.form.end.date' }),
    name: 'EndDate',
    type: 'date',
    required: true,
    initialState: isEdit || isDuplicate ? { value: planData.endDate } : undefined
  });
  // const activateCompliance = useFormField({
  //   label: formatMessage({ id: 'law-portal.legal.active.remainder.compliance' }),
  //   name: 'ActivateReminder',
  //   type: 'checkbox',
  //   required: false,
  //   initialState: isEdit || isDuplicate ? { value: planData.activateReminder } : { value: false },
  //   checkedValue(values) {
  //     setReminder(!reminder)
  //   },
  // });
  const planType = useFormField({
    type: 'single-select',
    name: 'type',
    required: true,
    label: formatMessage({ id: 'law-portal.create-plan.form-field-type' }),
    options: typeField.map(entityToSelectOption),
    initialState: isEdit || isDuplicate ? entityToSelectOption(typeField[typeField?.findIndex((val:any)=>val.id===planData.type)]) : accessToOtherRequirements&&accessToOurLaws?undefined:accessToOurLaws?entityToSelectOption(typeField[typeField?.findIndex((val:any)=>val.id===0)]):accessToOtherRequirements?entityToSelectOption(typeField[typeField?.findIndex((val:any)=>val.id===1)]):undefined,
    checkedValue(value) {
      setType(value)
    },
    disable: isEdit || isDuplicate ? true:accessToOurLaws&&accessToOtherRequirements?false:accessToOurLaws?true:accessToOtherRequirements?true:false
  });
  // const useSkcick = useFormField({
  //   label: '',
  //   name: 'ReminderDate',
  //   type: 'date',
  //   required: reminder ? false : true,
  //   placeholder: formatMessage({ id: 'law-portal.legal.send.out.reminder' }),
  //   initialState: isEdit || isDuplicate ? { value: planData.reminderDate } : undefined,
  //   disable: reminder ? true : false,

  // });
  const activateInterval = useFormField({
    type: 'single-select',
    name: 'Reminder',
    required: false,
    label: formatMessage({ id: 'law-portal.compliance.create.plan.field.utskick' }),
    options: reminderDateOption.map(entityToSelectOption),
    initialState: isEdit || isDuplicate ? planData.reminder === null ? undefined : entityToSelectOption(reminderDateOption[planData.reminder]) : undefined,
    icon: <Tooltip
      title={formatMessage({
        id: 'law-portal.compliance.create.plan.field.utskick.tooltip.msg',
      })}
      placement="right-start"
    >
      <InfoOutlined style={{ fontSize: "20px" }} />
    </Tooltip>
    // initialState: isEdit || isDuplicate ? entityToSelectOption(planData.customerCompanyUnitId) : undefined,
  });
  // const activateInterval = useFormField({
  //   label: formatMessage({ id: 'law-portal.legal.active.recuring.interval' }),
  //   name: 'IsRecurrent',
  //   type: 'checkbox',
  //   required: false,
  //   initialState: isEdit || isDuplicate ? { value: planData.isRecurrent } : { value: false },
  //   checkedValue(values) {
  //     setActive(!active)
  //   },
  // });
  const angeDagar = useFormField({
    label: formatMessage({ id: 'law-portal.compliance.create.plan.field.intervall' }),
    name: 'RecurrentInterval',
    type: 'integer',
    required: false,
    placeholder: formatMessage({ id: 'law-portal.legal.number.of.day' }),
    initialState: isEdit || isDuplicate ? { value: planData.recurrentInterval } : undefined,
    icon: <Tooltip
      title={formatMessage({
        id: 'law-portal.compliance.create.plan.field.intervall.tooltip.msg',
      })}
      placement="right-start"
    >
      <InfoOutlined style={{ fontSize: "20px" }} />
    </Tooltip>
  });

  const handleSubmit = async (data: FormValues, api: AptorApi, onSuccess: (key: string) => void) => {
    // data.type = PlanFeature.plan
    // if (!data.ActivateReminder) {
    //   delete data['ReminderDate']
    // }
    // if (!data.IsRecurrent) {
    //   delete data['RecurrentInterval']
    // }
    if (isEdit) {
      await api.updatePlanLaws(planData.id, data).then((res: any) => {
        api.addLaws(planData.id, { Legislations: selectedRows, type: data.type }).then(() => {
          setNewCreate(!newCreate)
          setEditData("")
          onSuccess('law-portal.complince.create-plan.sucess.msg')
          getCompliancePlan()
          handleAllControl()
        }).catch(() => { })
      }).catch(() => { })
      // await api.addLaws(planData.id, selectedRows)
      // setEditPlanID("")
      // setEditData("")
      // setNewCreate(false)
      // getCompliancePlan()
    } else {
      let cusId: number | null = customerId
      if (isDuplicate) {
        await api.createCompliancePlan(cusId, data).then((response: any) => {
          api.addLaws(response.id, { Legislations: selectedRows, type: data.type }).then(() => {
            onSuccess('law-portal.complince.create-plan.sucess.msg')
            setNewCreate(!newCreate)
            setDuplicatePlanData("")
            getCompliancePlan()
            handleAllControl()
          }).catch(() => { })
        }).catch(() => { })
        // const planLaws = await api.getPlanAllLawID(planData.id)
        // await api.addLaws(response.id, selectedRows)
        // onSuccess('law-portal.complince.create-plan.sucess.msg')
        // getCompliancePlan()
        // setNewCreate(false)
        // setDuplicatePlanData("")

      } else {
        await api.createCompliancePlan(cusId, data).then((response: any) => {
          api.addLaws(response.id, { Legislations: selectedRows, type: data.type }).then(() => {
            onSuccess('law-portal.complince.create-plan.sucess.msg')
            setNewCreate(!newCreate)
            getCompliancePlan()
            handleAllControl()
          }).catch(() => { })
        }).catch(() => { })
        // onSuccess('law-portal.complince.create-plan.sucess.msg')
        // getCompliancePlan()
        // setNewCreate(false)
      }
    }
  };
  // const handleCancel = () => {
  //   setEditPlanID("")
  // }
  return (
    <>
      <Panel>
        <Typography>{formatMessage({ id: 'law-portal.legal.complaince.create.new.planning' })}</Typography>
        <Form
          submit={handleSubmit}
          // cancel={isEdit ? handleCancel : undefined}
          submitLabel={formatMessage({ id: 'form.save' })}
          cancelLabel={formatMessage({ id: 'form.cancel' })}
          hideButton={selectedRows.length === 0 ? true : false}
          groups={[
            {
              grid: {
                type: 'column',
                items: [
                  { type: 'row', items: [namePlanning, chooseCompanyUnit, startFromDate, endFromDate] },
                  {
                    type: 'row', classStyle: classes.formstyle, items: [
                      planType,
                      // useSkcick, 
                      activateInterval,
                      angeDagar

                    ]
                  },
                ],
              },
            },
          ]}
        />
      </Panel>
      {type !== "" && companyUnit!==null && <ComplianceTable selectedRows={selectedRows} type={type} setSelectedRows={setSelectedRows}  companyUnit={companyUnit} />}
    </>
  );
};